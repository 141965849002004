import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2",attrs:{"height":"690px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c(VCardTitle,[_vm._v(" Penggunaan Workflow ")]),_c(VCardSubtitle,[_vm._v(" workspace "+_vm._s(_vm.currentWorkspace.name)+" ")])],1),_c('div',{staticClass:"px-4"},[_c('span',{staticClass:"text-caption"},[_vm._v("Pilih Rentang Waktu")]),_c(VSelect,{staticClass:"mt-1",staticStyle:{"max-width":"180px"},attrs:{"items":_vm.timeOptions,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.fetchWorkflowUserDashboardUsage(_vm.selectedTime)}},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})],1)]),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column"},[(!_vm.loadingDashboardWorkflowUsage)?_c('chartjs-line-chart',{attrs:{"height":500,"data":_vm.chartData,"options":_vm.lineChart.options,"plugins":_vm.plugins}}):[_c(VOverlay,{attrs:{"color":"black","opacity":"0.2","absolute":"","value":true}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)]],2)]),_c(VCardActions,{staticClass:"mt-8"},[_c('router-link',{attrs:{"to":{ name: 'workspace-workflow-reports' }}},[_c(VBtn,{staticClass:"mt-auto",attrs:{"color":"primary","outlined":"","small":"","text":""}},[_vm._v(" Detail Penggunaan Workflow ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }