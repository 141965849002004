import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"465px"}},[_c('div',{staticClass:"pa-4 d-flex flex-column align-start slide-height"},[_c('span',{staticClass:"text-subtitle-1 font-medium primary--text"},[_vm._v(" Integrasi Workflow ")]),_c('span',{staticClass:"text-subtitle-2 mt-2"},[_vm._v(" Status Integrasi Workflow mu di OriensCRM ")]),_c('div',{staticClass:"w-100 my-3"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dashboardWorkflowList,"loading":_vm.loadingDashboardWorkflow,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(item.platform.name))])]}},{key:"item.account_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(item.account_count))])]}}],null,true)})],1),_c('router-link',{staticClass:"mt-10",attrs:{"to":{ name: 'integrations' }}},[_c(VBtn,{staticClass:"mt-auto",attrs:{"color":"primary","outlined":"","small":"","text":""}},[_vm._v(" Sambungkan Akun ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }