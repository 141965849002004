<template>
  <div>
    <v-row class="match-height">
      <!-- <v-col
        cols="12"
        md="6"
        lg="4"
      >

      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
      </v-col> -->
      <v-col
        cols="12"
        lg="4"
      >
        <v-row>
          <v-col
            cols="12"
            xl="6"
          >
            <welcome-card />
          </v-col>
          <v-col
            cols="12"
            xl="6"
          >
            <integration-card />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="8"
      >
        <!-- <assignment-card /> -->
        <workflow-user-chart />
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="6"
      >
        <workflow-tutorial-card />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <workflow-info-card />
      </v-col>
    </v-row>
    <!-- <v-row class="match-height">
      <v-col
        cols="12"
        md="4"
      >
        <v-card min-height="120px">
          <v-card-text>
            Slot Dashboard
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card min-height="120px">
          <v-card-text>
            Slot Dashboard
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card min-height="120px">
          <v-card-text>
            Slot Dashboard
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import WelcomeCard from './WelcomeCard.vue'
// import AssignmentCard from './AssignmentCard.vue'
import WorkflowUserChart from './components/WorkflowUserChart.vue'
import IntegrationCard from './IntegrationCard.vue'
import WorkflowInfoCard from './components/WorkflowInfoCard.vue'
import WorkflowTutorialCard from './components/WorkflowTutorialCard.vue'
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
// import Mention from '@ckeditor/ckeditor5-mention/src/mention'
import { ref } from '@vue/composition-api'

export default {
  components: {
    WelcomeCard,
    // AssignmentCard,
    IntegrationCard,
    WorkflowUserChart,
    WorkflowInfoCard,
    WorkflowTutorialCard,
  },
  setup() {
    const editorData = ref('')
    // const editorConfig = ref({
    //   plugins: [Mention],
    //   toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    //   mention: {
    //     feeds: [
    //       {
    //         marker: '@',
    //         feed: ['Anna', 'Thomas', 'John', 'Jane', 'Amy', 'Adam', 'Anne', 'Alice'],
    //         minimumCharacters: 0,
    //       },
    //     ],
    //   },
    // })

    return {
      // ClassicEditor,
      editorData,
      // editorConfig,
    }
  },
}
</script>

<style lang="scss">

</style>
