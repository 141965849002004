<template>
  <v-card height="465px">
    <div class="pa-4 d-flex flex-column align-start slide-height">
      <span class="text-subtitle-1 font-medium primary--text">
        Integrasi Workflow
      </span>
      <span class="text-subtitle-2 mt-2">
        Status Integrasi Workflow mu di OriensCRM
      </span>
      <div class="w-100 my-3">
        <v-data-table
          :headers="headers"
          :items="dashboardWorkflowList"
          :loading="loadingDashboardWorkflow"
          dense
          disable-filtering
          disable-pagination
          disable-sort
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <span class="text-subtitle-2 font-medium">{{ item.platform.name }}</span>
          </template>
          <template v-slot:[`item.account_count`]="{ item }">
            <span class="text-subtitle-2 font-medium">{{ item.account_count }}</span>
          </template>
        </v-data-table>
      </div>
      <router-link
        :to="{ name: 'integrations' }"
        class="mt-10"
      >
        <v-btn
          color="primary"
          outlined
          class="mt-auto"
          small
          text
        >
          Sambungkan Akun
        </v-btn>
      </router-link>
    </div>
  </v-card>
</template>

<script>
import 'swiper/css/swiper.css'
import useAutomation from '@/composables/useAutomation'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
  },
  setup() {
    const { fetchWorkflowDashboardStatus, loadingDashboardWorkflow, dashboardWorkflowList } = useAutomation()
    const headers = [
      { text: 'Nama', value: 'name' },
      { text: 'Jumlah Akun', value: 'account_count' },
      { text: 'Jumlah Workflow', value: 'workflow_count' },
    ]

    onMounted(() => {
      fetchWorkflowDashboardStatus({})
    })

    return {
      headers,
      loadingDashboardWorkflow,
      dashboardWorkflowList,
    }
  },
}
</script>

<style lang="scss">
.lottie-welcome {
  position: absolute;
  bottom: -20px;
  right: 0px;
  height: 80%;
  transform: scale(1.4);
  overflow: hidden;
}

.slide-height {
  height: calc(100% - 30px) !important;
}
</style>
